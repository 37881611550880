import logo from './uvuli-logo.png';
import './App.css';
import './output.css';
import TabContainer from './TabContainer.js';

function App() {
  return (
    <div className="App">
      <div class="p-2 bg-verylightgray">
        <div class="w-1/5">
          <img class="object-center" src={logo}  alt="logo" />
        </div>
        <span class="text-primary font-bold">Bringing Emotionally-Intelligent Dating to Charlotte</span>
      </div>
      <TabContainer>
      </TabContainer>
    </div>
  );
}

export default App;
