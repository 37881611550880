import { useState } from 'react';
import { Turnstile } from '@marsidev/react-turnstile';
import isValidEmail from './EmailValidator.js';

function SignUpForm() {

  const [email, setEmail] = useState('');
  const [showButtonState, setShowButtonState] = useState(true);
  const [showTurnstileState, setShowTurnstileState] = useState(true);
  const [buttonText, setButtonText] = useState("I'm interested");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [turnstileToken, setTurnstileToken] = useState('');

  const addEmailToList = () => {
    let formData = new FormData();
    formData.append('token', turnstileToken);
    (async () => {
      const response = await fetch("/AddEmailToList?emailToAdd=" + email, {
        method: "POST",
        body: formData
      });
      console.log(response);
    })();
  };

  function emailSubmitted() {
    setShowTurnstileState(false);
    setShowButtonState(false);
    addEmailToList();
  }

  function turnstileCallback(token) {
    setTurnstileToken(token);
  }

  function emailInputChanged(e) {
    setEmail(e.target.value);
    console.log(email);
    console.log(isValidEmail(email));
    if(email && isValidEmail(email)) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }

  return (
    <div class="w-full max-w-xs">
      <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 border-secondary">
        <include src="https://challenges.cloudflare.com/turnstile/v0/api.js"></include>
        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2">
            Please enter your email if you&#39;re interested in receiving updates about Uvuli
          </label>
          <input
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            value={email}
            onChange={emailInputChanged}
            id="email"
            type="text"
            placeholder="Email"/>
          <div class="text-center">
            <button
              className="bg-primary text-white font-bold py-2 px-4 rounded m-4 disabled:bg-slate-50"
              style={{ display: showButtonState ? 'inline' : 'none' }}
              onClick={emailSubmitted}
              disabled={isButtonDisabled} >
              
              {buttonText}
            </button>
            <div className="text-primary font-bold py-2 px-4 m-4" style={{ display: showButtonState || showTurnstileState  ? 'none' : 'block' }} >
              Submitted! Thank you!
            </div>
            <div className="flex justify-center pt-2" style={{ display: showTurnstileState  ? 'flex' : 'none' }}>
              <Turnstile siteKey='0x4AAAAAAAb6jkga9Oe1Qb5F' onSuccess={turnstileCallback} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUpForm;